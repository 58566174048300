<template lang="pug">
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import { isValidPhoneNumber } from 'libphonenumber-js';

import checkEmail from '@/utils/checkEmail';

const Facebook = () => import('@/components/serviceComponents/svg/social/Facebook');
const Instagram = () => import('@/components/serviceComponents/svg/social/Instagram');
const Twitter = () => import('@/components/serviceComponents/svg/social/Twitter');
const VK = () => import('@/components/serviceComponents/svg/social/VK');
const Whatsup = () => import('@/components/serviceComponents/svg/social/Whatsup');
const Youtube = () => import('@/components/serviceComponents/svg/social/Youtube');
const Google = () => import('@/components/serviceComponents/svg/social/Google');

export default {
  name: 'Signin-NH',
  components: {
    Facebook,
    Google,
    Instagram,
    Twitter,
    VK,
    Whatsup,
    Youtube,
  },
  data: () => ({
    userData: {
      phone: '',
      password: '',
      name: '',
      surname: '',
      pin: '',
      nrc: '',
      countryCallingCode: '',
      email: '',
      confirmToS: false,
      confirmOffers: false,
      day: 1,
      birthday: null,
      month: null,
      year: null,
      address: {
        country: null,
      },
    },
    updateKey1: 0,
    updateKey2: 1,
    userPhoneNumber: '',
    phoneIsValid: true,
    passwordIsCorrect: true,
    nameIsCorrect: true,
    surnameIsCorrect: true,
    pinIsCorrect: true,
    phoneError: false,
    currentCountryCode: '',
    isClicked: false,
    isClickedCross: false,
    isSignupFormClicked: false,
    isChevronClicked: false,
    isRemindPassword: false,
    tabSwitcher: {
      isPhoneActive: true,
      isEmailActive: false,
    },
    checkEmail: true,
    socialsClick: {google: false},
    isToSValid: true,
    siteKey: process.env.VUE_APP_RECAPCHA_KEY,
    phonePlaceholder: '+ X (XXX) XXX XX XX',
    isEyeOpen: false,
    isRemindClicked: false,
    isSigninABClicked: false,
    isSignupABClicked: false,
    expandDaysDD: false,
    expandMonthDD: false,
    expandYearDD: false,
    expandCountryDD: false,
    dateArray: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16',
      '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
    yearArray: [],
    selectedDayValue: null,
    selectedMonthValue: null,
    selectedYearValue: null,
    selectedCountryValue: null,
    clearFields: false,
    datePickerIncorrect: false,
    monthPickerIncorrect: false,
    yearPickerIncorrect: false,
    countryPickerIncorrect: false,
  }),
  computed: {
    ...mapGetters({
      isAuthUser: 'auth/isAuthUser',
      currentLanguage: 'user/getLanguage',
      isSigninVisible: 'auth/isSigninVisible',
      isSignupVisible: 'auth/isSignupVisible',
      getSocials: 'auth/getSocials',
      brand: 'main/getBrand',
      getTwoFA: 'auth/getTwoFA',
      userProfile: 'user/getProfile',
    }),
    checkPassword() {
      return this.passwordIsCorrect;
    },
    checkPhone() {
      return this.phoneIsValid;
    },
    getUserId() {
      return this.userProfile != null && this.userProfile.hasOwnProperty('id') ? this.userProfile.id : null;
    },
    defaultFirstname() {
      return this.userData != null && this.userData.name !== '' ? this.userData.name : null;
    },
    defaultLastname() {
      return this.userData != null && this.userData.surname !== '' ? this.userData.surname : null;
    },
    monthArray() {
      return [
        {title: this.$t('profile.editProfile.firstMonthSelectLabel'), month: '01'},
        {title: this.$t('profile.editProfile.secondMonthSelectLabel'), month: '02'},
        {title: this.$t('profile.editProfile.thirdMonthSelectLabel'), month: '03'},
        {title: this.$t('profile.editProfile.fourthMonthSelectLabel'), month: '04'},
        {title: this.$t('profile.editProfile.fifthMonthSelectLabel'), month: '05'},
        {title: this.$t('profile.editProfile.sixthMonthSelectLabel'), month: '06'},
        {title: this.$t('profile.editProfile.seventhMonthSelectLabel'), month: '07'},
        {title: this.$t('profile.editProfile.eightMonthSelectLabel'), month: '08'},
        {title: this.$t('profile.editProfile.ninthMonthSelectLabel'), month: '09'},
        {title: this.$t('profile.editProfile.tenthMonthSelectLabel'), month: '10'},
        {title: this.$t('profile.editProfile.eleventhMonthSelectLabel'), month: '11'},
        {title: this.$t('profile.editProfile.twelfthMonthSelectLabel'), month: '12'},
      ];
    },
    defaultCountry() {
      if (this.editProfile != null) {
        if (this.editProfile.hasOwnProperty('address')) {
          if (this.editProfile.address != null && this.editProfile.address.hasOwnProperty('country')) {
            return this.editProfile.address.country;
          }

          return null;
        }

        return null;
      }

      return null;
    },
    getCountries() {
      return this.brand != null && this.brand.hasOwnProperty('signup_countries') ? this.brand['signup_countries'] : [];
    },
  },
  methods: {
    ...mapActions({
      doLogin: 'auth/doLogin',
      fetchInitSetting: 'main/fetchInitSetting',
      doSignup: 'auth/doSignup',
      doForgotPassword: 'auth/doForgotPassword',
      fetchSocials: 'auth/fetchSocials',
    }),
    ...mapMutations({
      SET_SIGNIN_VISIBLE: 'auth/SET_SIGNIN_VISIBLE',
      SET_SIGNUP_VISIBLE: 'auth/SET_SIGNUP_VISIBLE',
      SET_TWOFA: 'auth/SET_TWOFA',
    }),
    getImgForSocial (index) {
      switch (index) {
        case 'google':
          return Google;
        case 'instagram':
          return Instagram;
        case 'twitter':
          return Twitter;
        case 'vkontakte':
          return VK;
        case 'whatsapp':
          return Whatsup;
        case 'youtube':
          return Youtube;
      }
      return null;
    },
    /**
     * обработчик нажатия клавиши в поле ввода номера телефона
     */
    keypressPhone() {
      if (this.userPhoneNumber && this.userPhoneNumber.length > 0 && this.userPhoneNumber.indexOf('+') === -1) {
        this.userPhoneNumber = '+' + this.userPhoneNumber;
      }
    },
    keypressPassword(e) {
      if (e.key === 'Enter') {
        this.callSigninCheck();
      }
    },
    keypressForm(e){
      if (e.keyCode === 27) {
        this.closeForm();
      }
    },
    /**
     * обработчик ввода в поле ввода номера телефона
     */
    inputPhone(e) {
      if (e.target.value !== '' && e.target.value.indexOf('+') === -1) {
        this.userPhoneNumber = '+' + this.userPhoneNumber;
      }
    },
    checkInputPin() {
      if (this.userData.pin.length !== 0 && this.userData.pin.length === 4) {
          this.pinIsCorrect = true;

          return true;
        }
        else {
          this.pinIsCorrect = false;

          return false;
        }
    },
    /**
     * проверка пароля пользователя
     */
    checkUserPassword() {
      if (this.userData.password.length >= 7) {
        this.passwordIsCorrect = true;

        return true;
      }
      else {
        this.passwordIsCorrect = false;

        return false;
      }
    },
    /**
     * проверка email пользователя
     */
    checkUserEmail() {
      if (checkEmail(this.userData.email)) {
        this.checkEmail = true;

        return true;
      }
      else {
        this.checkEmail = false;

        return false;
      }
    },
    /**
     * проверка телефона пользователя,
     * isValidPhoneNumber метод из бибилиотеки libphonenumber-js
     */
    checkUserPhone() {
      if (isValidPhoneNumber(this.userPhoneNumber, this.currentLanguage.split('-')[1])) {
        this.phoneIsValid = true;
        this.phoneError = false;

        return true;
      }
      else {
        this.phoneIsValid = false;
        this.phoneError = true;

        return false;
      }
    },
    /**
     * проверка имени пользователя
     */
    checkUserName() {
      if (this.userData.name.length >= 4) {
        this.nameIsCorrect = true;

        return true;
      }
      else {
        this.nameIsCorrect = false;

        return false;
      }
    },
    /**
     * проверка фамилии пользователя
     */
    checkUserSurname() {
      if (this.userData.surname.length > 2) {
        this.surnameIsCorrect = true;

        return true;
      }
      else {
        this.surnameIsCorrect = false;

        return false;
      }
    },
    /**
     * проверка чекбокса правил
     */
    checkUserToS() {
      if (this.userData.confirmToS) {
        this.isToSValid = true;

        return true;
      }
      else {
        this.isToSValid = false;

        return false;
      }
    },
    closeForm() {
      this.isClickedCross = true;
      this.SET_SIGNIN_VISIBLE(false);
      this.SET_SIGNUP_VISIBLE(false);
    },
    showForgotData() {
      this.isRemindPassword = true;
      this.phoneIsValid = true;
      this.phoneError = false;
      this.checkEmail = true;
    },
    /**
     * при нажатии на логин проверяем поля, и толкьо в случае успеха вызываем исполнение
     * гугл рекапчи
     */
    callSigninCheck() {
      const phoneBool = this.tabSwitcher.isPhoneActive ? this.checkUserPhone() : true;
      const emailBool = this.tabSwitcher.isEmailActive ? this.checkUserEmail() : true;
      const passwordBool = this.checkUserPassword();

      if (phoneBool && emailBool && passwordBool) {
        this.$refs.recaptchaLog.execute();
      }
    },
    async nhSignin(recaptchaToken) {
      const phoneBool = this.tabSwitcher.isPhoneActive ? this.checkUserPhone() : true;
      const emailBool = this.tabSwitcher.isEmailActive ? this.checkUserEmail() : true;
      const passwordBool = this.checkUserPassword();

      if (phoneBool && emailBool && passwordBool) {
        this.isSigninABClicked = true;

        let loginPayload = {
          password: this.userData.password,
          token: recaptchaToken
        };

        if (this.tabSwitcher.isPhoneActive) {
          loginPayload.phone = this.userPhoneNumber;
        } else {
          loginPayload.email = this.userData.email;
        }
        if (this.getTwoFA !== null) {
          loginPayload['2f_code'] = this.getTwoFA;
        }
        let result = await this.doLogin(loginPayload);

        if (this.$metrika) {
          this.$metrika.reachGoal('loginClick');
        }

        if (!result) {
          this.onCaptchaExpired();
        }

        if (this.isAuthUser) {
          if (this.getUserId != null) {
            if (this.$metrika) {
              this.$metrika.setUserID(this.getUserId);
            }
          }

          if (this.$metrika) {
            this.$metrika.reachGoal('loginSuccess');
          }
        }

        setTimeout(() => {
          this.isSigninABClicked = false;
        }, 500);
      }
    },
    callSignupCheck() {
      const phoneBool = this.tabSwitcher.isPhoneActive ? this.checkUserPhone() : true;
      const emailBool = this.tabSwitcher.isEmailActive ? this.checkUserEmail() : true;
      const tosBool = this.checkUserToS();
      const userNameBool = this.checkUserName();
      const surNameBool = this.checkUserSurname();
      const datepickerBool = this.checkDatePicker();
      const monthpickerBool = this.checkMonthPicker();
      const yearpickerBool = this.checkYearPicker();
      const countrypickerBool = this.checkCountryPicker();

      if (phoneBool && emailBool && tosBool && userNameBool && surNameBool && datepickerBool
          && monthpickerBool && yearpickerBool && countrypickerBool) {
        this.$refs.recaptchaReg.execute();
      }
    },
    async nhSignup(recaptchaToken) {
      const phoneBool = this.tabSwitcher.isPhoneActive ? this.checkUserPhone() : true;
      const emailBool = this.tabSwitcher.isEmailActive ? this.checkUserEmail() : true;
      const tosBool = this.checkUserToS();
      const userNameBool = this.checkUserName();
      const surNameBool = this.checkUserSurname();
      const datepickerBool = this.checkDatePicker();
      const monthpickerBool = this.checkMonthPicker();
      const yearpickerBool = this.checkYearPicker();
      const countrypickerBool = this.checkCountryPicker();

      if (phoneBool && emailBool && tosBool && userNameBool && surNameBool && datepickerBool
          && monthpickerBool && yearpickerBool && countrypickerBool) {
        this.isSignupABClicked = true;

        const birthDayData = this.userData.year == null || this.userData.month == null ?
            null : `${this.userData.year}-${this.userData.month}-${this.userData.day}`;
        const address = this.userData.address != null ?
            {country: this.userData.address.country } : null;

        let signupPayload = {
          name: this.userData.name,
          surname: this.userData.surname,
          phone: this.tabSwitcher.isPhoneActive ? this.userPhoneNumber : null,
          email: this.tabSwitcher.isPhoneActive ? null : this.userData.email,
          token: recaptchaToken,
          birthday: birthDayData,
          promo_notify: this.userData.confirmOffers,
          address: address,
        };
        let result = await this.doSignup(signupPayload);

        if (this.$metrika) {
          this.$metrika.reachGoal('registerClick');
        }

        if (!result) {
          this.onCaptchaExpired();
        }
        else {
          if (this.$metrika) {
            this.$metrika.reachGoal('registerSuccess');
          }
        }

        setTimeout(() => {
          this.isSignupABClicked = false;
        }, 500);
      }
    },
    /**
     * при нажатии на логин проверяем поля, и толкьо в случае успеха вызываем исполнение
     * гугл рекапчи
     */
    callSendPasswordCheck() {
      const phoneBool = this.tabSwitcher.isPhoneActive ? this.checkUserPhone() : true;
      const emailBool = this.tabSwitcher.isEmailActive ? this.checkUserEmail() : true;

      if (phoneBool && emailBool) {
        this.$refs.recaptchaPass.execute();
      }
    },
    async sendPassword(recaptchaToken) {
      this.isClicked = true;
      const phoneBool = this.tabSwitcher.isPhoneActive ? this.checkUserPhone() : true;
      const emailBool = this.tabSwitcher.isEmailActive ? this.checkUserEmail() : true;

      if (phoneBool && emailBool) {
        let result;
        if (this.tabSwitcher.isPhoneActive) {
          result = await this.doForgotPassword({
            phone: this.userPhoneNumber,
            token: recaptchaToken,
          });
        } else {
          result = await this.doForgotPassword({
            email: this.userData.email,
            token: recaptchaToken,
          });
        }
        if (!result){
          this.onCaptchaExpired();
        }

        this.isRemindPassword = false;
        this.userData.phone = '';
      }
    },
    /**
     * method for switching between phone and email input fields
     */
    tabSwitcherHandler(title) {
      switch(title) {
        case 'phone': {
          this.tabSwitcher.isPhoneActive = true;
          this.tabSwitcher.isEmailActive = false;
          this.checkEmail = true;
          this.phoneIsValid = true;
          this.passwordIsCorrect = true;
          this.phoneError = false;
          this.isToSValid = true;
          return;
        }
        case 'email': {
          this.tabSwitcher.isPhoneActive = false;
          this.tabSwitcher.isEmailActive = true;
          this.userData.phone = '';
          this.checkEmail = true;
          this.passwordIsCorrect = true;
          this.phoneError = false;
          this.isToSValid = true;
          return;
        }
      }
    },
    /**
     * метод открытия ссылки на социальную сеть в новой вкладке браузера
     */
    callSocial(index) {
      this.socialsClick[index] = true;

      setTimeout(() => {
        window.location = this.getSocials[index].url;
        this.socialsClick[index] = false;
      }, 50);
    },
    /**
     * метод по отображению/скрытию пароля
     */
    switchPasswordType() {
      let pass = this.$refs.password;

      if (pass.type === 'password') {
        pass.type = 'text';
        this.isEyeOpen = true;
      }
      else if (pass.type === 'text') {
        pass.type = 'password';
        this.isEyeOpen = false;
      }
    },
    onCaptchaExpired() {
      if (this.$refs.recaptchaLog != null) {
        this.$refs.recaptchaLog.reset();
      }

      if (this.$refs.recaptchaReg != null) {
        this.$refs.recaptchaReg.reset();
      }

      if (this.$refs.recaptchaPass != null) {
        this.$refs.recaptchaPass.reset();
      }
    },
    switchAuthForm(arg) {
      this.userData.password = '';
      this.clearInputs();

      if (arg === 'login') {
        this.SET_SIGNIN_VISIBLE(true);
        this.SET_SIGNUP_VISIBLE(false);
      }

      if (arg === 'signup') {
        this.SET_SIGNUP_VISIBLE(true);
        this.SET_SIGNIN_VISIBLE(false);
      }
    },
    /**
     * метод-слушатель событий из компонента Кнопка
     */
    listenBtnEvent(data) {
      if (data) {
        switch(data) {
          case 'applyCallSigninCheck': {
            this.callSigninCheck();
            return;
          }
          case 'applyCallSendPasswordCheck': {
            this.callSendPasswordCheck();
            return;
          }
          case 'applyCallSignupCheck': {
            this.callSignupCheck();
            return;
          }
        }
      }
    },
    /**
     * обработчик потери фокуса на поле номера телефона
     */
    blurPhone() {
      if (this.userPhoneNumber !== '') {
        this.checkUserPhone();
      }
    },
    /**
     * обработчик потери фокуса на поле email
     */
    blurEmail() {
      if (this.userData.email !== '') {
        this.checkUserEmail();
      }
    },
    /**
     * метод-слушатель событий из компонента Checkbox
     */
    listenCheckbox(data) {
      if (data != null) {

        switch (data.name) {
          case 'offersCheckbox': {
            this.userData.confirmOffers = data.value;
            return;
          }
          case 'tosCheckbox': {
            this.userData.confirmToS = data.value;
            return;
          }
        }
      }
    },
    /**
     * Метод-обработчик для выбора даты.
     */
    listenToggle(data, arg) {
      if (data) {
        switch (arg) {
          case 'day': {
            this.userData.day = data;
            this.selectedDayValue = data;
            return;
          }
          case 'month': {
            this.userData.month = data.month;
            this.selectedMonthValue = this.$t(data.title);
            return;
          }
          case 'year': {
            this.userData.year = data;
            this.selectedYearValue = data;
            return;
          }
          case 'country': {
            if (this.userData.address != null) {
              this.userData.address.country = data;
            }
            else {
              this.userData.address = {};
              this.userData.address.country = data;
            }

            this.selectedCountryValue = data;
            return;
          }
        }
      }
    },
    /**
     *
     * @param ddArg string
     * преключатель активного дропдауна
     */
    switchDropdown(ddArg) {
      if (ddArg === 'days') {
        setTimeout(() => {
          this.expandDaysDD = !this.expandDaysDD;
        }, 300);
      } else if (ddArg === 'month') {
        setTimeout(() => {
          this.expandMonthDD = !this.expandMonthDD;
        }, 300);
      } else if (ddArg === 'year') {
        setTimeout(() => {
          this.expandYearDD = !this.expandYearDD;
        }, 300);
      } else if (ddArg === 'country') {
        setTimeout(() => {
          this.expandCountryDD = !this.expandCountryDD;
        }, 300);
      }
    },
    /**
     * метод-слушатель ввода данных из компонентов ввода
     */
    listenTextInput(data) {
      if (data) {
        switch (data.name) {
          case 'fname': {
            this.userData.name = data.value;
            return;
          }
          case 'lname': {
            this.userData.surname = data.value;
            return;
          }
          case 'city': {
            if (this.userData.address != null) {
              this.userData.address.city = data.value;
            }
            else {
              this.userData.address = {};
              this.userData.address.city = data.value;
            }

            return;
          }
          case 'address': {
            if (this.userData.address != null) {
              this.userData.address.address = data.value;
            }
            else {
              this.userData.address = {};
              this.userData.address.address = data.value;
            }

            return;
          }
        }
      }
    },
    /**
     * генерируем последовательность годов для дропдауна
     * стартуем от текущего минус 18 лет, заканчиваем -
     * полученное значение минус 72 года.
     */
    generateYearsSeq() {
      let arr = [];
      const currentYear = new Date().getFullYear() - 18;
      for (let i = currentYear; i >= (currentYear - 75); i--) {
        arr.push(i);
      }
      this.yearArray = arr;
    },
    /**
     * метод для заполнения исходных данных формы при открытии страницы
     */
    async updateUserData() {
      await this.$nextTick(() => {
        this.generateYearsSeq();

        this.selectedDayValue = this.$t('profile.editProfile.selectDayLabel');
        this.selectedMonthValue = this.$t('profile.editProfile.selectMonthLabel');
        this.selectedYearValue = this.$t('profile.editProfile.selectYearLabel');
        this.selectedCountryValue = this.$t('profile.editProfile.editProfileCountryLabel');
      });
    },
    /**
     * проверка datepicker
     */
    checkDatePicker() {
      if (this.selectedDayValue === this.$t('profile.editProfile.selectDayLabel')) {
        this.datePickerIncorrect = true;

        return false;
      }
      else {
        this.datePickerIncorrect = false;
        return true;
      }
    },
    /**
     * проверка monthpicker
     */
    checkMonthPicker() {
      if (this.selectedMonthValue != this.$t('profile.editProfile.selectMonthLabel')) {
        if ((this.monthArray.find(month => month.title == this.selectedMonthValue) != undefined)) {
          this.monthPickerIncorrect = false;

          return true;
        }
      }
      else {
        this.monthPickerIncorrect = true;
        return false;
      }
    },
    /**
     * проверка yearpicker
     */
    checkYearPicker() {
      if (!isNaN(parseInt(this.selectedYearValue))) {
        this.yearPickerIncorrect = false;

        return true;
      }
      else {
        this.yearPickerIncorrect = true;

        return false;
      }
    },
    /**
     * проверка yearpicker
     */
    checkCountryPicker() {
      if (this.selectedCountryValue != this.$t('profile.editProfile.editProfileCountryLabel')) {
        this.countryPickerIncorrect = false;

        return true;
      }
      else {
        this.countryPickerIncorrect = true;

        return false;
      }
    },
    /**
     * очистка полей ввода при переключении кнопок Логин-Регистрация
     */
    clearInputs() {
      this.updateKey1++;
      this.updateKey2++;
      this.phoneIsValid = true;
      this.phoneError = false;
      this.datePickerIncorrect = false;
      this.monthPickerIncorrect = false;
      this.yearPickerIncorrect = false;
      this.nameIsCorrect = true;
      this.surnameIsCorrect = true;
      this.countryPickerIncorrect = false;
      this.isToSValid = true;
    },
  },
  watch: {
    isSigninVisible(newValue) {
      if (newValue === true) {
        this.SET_SIGNUP_VISIBLE(false);
      }
    },
    isSignupVisible(newValue) {
      if (newValue === true) {
        this.SET_SIGNIN_VISIBLE(false);
      }
    },
    userData: {
      deep: true,
      handler(newUserData) {
        if (newUserData.password && newUserData.password.length >= 3) {
          this.passwordIsCorrect = true;
        }

        if (newUserData.phone === '+') {
          this.userData.phone = '';
        }

        if ((/\(|\)/).test(newUserData.phone)) {
          this.userData.phone = this.userData.phone !== '' ? this.userData.phone.replace(/\(|\)/g, '') : '';
        }

        if ((/\-/).test(newUserData.phone)) {
          this.userData.phone = this.userData.phone !== '' ? this.userData.phone.replace(/\-/g, ' ') : '';
        }

        if (newUserData.name !== '') {
          this.nameIsCorrect = true;
        }

        if (newUserData.surname !== '') {
          this.surnameIsCorrect = true;
        }

        /**
        * watch for email field
        */
        if (newUserData.email !== '') {
          if (checkEmail(newUserData.email)) {
            this.checkEmail = true;
          }
        }

        if (newUserData.confirmToS) {
          this.isToSValid = true;
        }
        else {
          this.isToSValid = false;
        }
      }
    },
    userPhoneNumber(newUserPhoneNumber) {
      if (newUserPhoneNumber !== '') {
        if (checkEmail(newUserPhoneNumber)){
          this.tabSwitcher.isPhoneActive = false;
          this.tabSwitcher.isEmailActive = true;
          this.userData.email = newUserPhoneNumber;
          this.userPhoneNumber = '';
        } else {
          if (isValidPhoneNumber(this.userPhoneNumber, this.currentLanguage.split('-')[1])) {
            this.phoneIsValid = true;
            this.phoneError = false;
          }
        }
      }
      else {
        this.phoneIsValid = true;
        this.phoneError = false;
      }
    },
    isSignupFormClicked(newSignupValue) {
      if (newSignupValue) {
        setTimeout(() => {
          this.isSignupFormClicked = false;
        }, 250);
      }
    },
    isClicked(newIsClicked) {
      if (newIsClicked) {
        setTimeout(() => {
          this.isClicked = false;
        }, 300);
      }
    },
    isRemindPassword(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.$nextTick(() => {
          this.$refs.recaptchaLog.reset();
        });
      }
    },
    getTwoFA(newVal, oldVal) {
      if (newVal != oldVal) {
        this.onCaptchaExpired();
        this.callSigninCheck();
      }
    },
    selectedDayValue(newVal, oldVal) {
      if (newVal !== oldVal && oldVal != null) {
        this.checkDatePicker();
      }
    },
    selectedMonthValue(newVal, oldVal) {
      if (newVal !== oldVal && oldVal != null) {
        this.checkMonthPicker();
      }
    },
    selectedYearValue(newVal, oldVal) {
      if (newVal !== oldVal && oldVal != null) {
        this.checkYearPicker();
      }
    },
    selectedCountryValue(newVal, oldVal) {
      if (newVal !== oldVal && oldVal != null) {
        this.checkCountryPicker();
      }
    },
  },
  async mounted() {
    await this.fetchSocials();
    if (this.isSigninVisible && !this.isRemindPassword) {
      if (this.$metrika) {
        this.$metrika.reachGoal('loginOpen');
      }
    }
    else if (this.isSignupVisible && !this.isRemindPassword) {
      if (this.$metrika) {
        this.$metrika.reachGoal('registerOpen');
      }
    }

    window.addEventListener('keydown', this.keypressForm);
    if (document.querySelector('div[title]') != null) {
      document.querySelector('div[title]').style.zIndex='-10';
    }
    await this.fetchInitSetting();
    await this.updateUserData();

    await this.$nextTick(() => {
      if (this.mobileRegNumber) {
        this.userData.phone = this.mobileRegNumber;
        document.querySelector('.input-tel__input').value = this.mobileRegNumber;
      }
    });
  },
  beforeDestroy() {
    this.SET_TWOFA(null);
    this.clearFields = true;
  }
};
</script>

<style lang="scss">
</style>