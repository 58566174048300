<template lang="pug">
  .sup-signin-nh-wrapper
    VueRecaptcha(v-if="isSigninVisible && !isRemindPassword" ref="recaptchaLog" size="invisible" :sitekey="siteKey"
                    @verify="nhSignin" @expired="onCaptchaExpired" badge="bottomright"
                    :loadRecaptchaScript="true" render="explicit")

    VueRecaptcha(v-if="isSignupVisible" ref="recaptchaReg" size="invisible" :sitekey="siteKey"
                @verify="nhSignup" @expired="onCaptchaExpired" badge="bottomright"
                :loadRecaptchaScript="true")

    VueRecaptcha(v-if="isRemindPassword" ref="recaptchaPass" size="invisible" :sitekey="siteKey"
                @verify="sendPassword" @expired="onCaptchaExpired" badge="bottomright"
                :loadRecaptchaScript="true")

    .signin-nh-wrapper
      .head
        .head__close(@click="closeForm")
          CrossShadowOff(:isClickedCross="isClickedCross" :width="16" :height="16")

      .middle
        form.auth(:class="{ 'reg': isSignupVisible }")
          .active-link-wrapper(:class="{ 'rem': isRemindPassword }")
            .login-wrapper(v-show="!isRemindPassword" @click="switchAuthForm('login')")
              Button(:isActive="isSigninVisible"
                    :title="$t('auth.signinLabel')")

            .signup-wrapper(v-show="!isRemindPassword" @click="switchAuthForm('signup')")
              Button(:isActive="isSignupVisible"
                    :title="$t('auth.signupLabel')")

            .remind-pass-wrapper(v-show="isRemindPassword") {{ $t('auth.passwordRestLabel') }}

          .tab-switcher
            .phone-title(:class="{ 'active': tabSwitcher.isPhoneActive }"
                        @click="tabSwitcherHandler('phone')") {{ $t('auth.phoneLabel') }}
            .email-title(:class="{ 'active': tabSwitcher.isEmailActive }"
                        @click="tabSwitcherHandler('email')") {{ $t('auth.emailLabel') }}


          .cont(:class="{ 'reg': isSignupVisible }")
            .signup-field-wrapper.names-wrapper(v-if="isSignupVisible")
              .fname-wrapper
                TextInput(name="fname" :renderedTextLabel="$t('profile.editProfile.editProfileFirstnameLabel')"
                  :key="updateKey1" :defaultValue="defaultFirstname"
                  rules="required|minFour" :floatingLabel="true" vid="fname"
                  @textInputEmit="listenTextInput" :isError="!nameIsCorrect"
                  :skipIfEmpty="false" :clearFields="clearFields")

              .lname-wrapper
                TextInput(name="lname" :renderedTextLabel="$t('profile.editProfile.editProfileLastnameLabel')"
                  :key="updateKey2" :defaultValue="defaultLastname"
                  :floatingLabel="true" vid="lname" rules="required|minFour"
                  @textInputEmit="listenTextInput" :isError="!surnameIsCorrect"
                  :skipIfEmpty="false" :clearFields="clearFields")

            .signup-field-wrapper.dpi-wrapper(v-if="isSignupVisible")
              ValidationProvider.datepicker-wrapper(@click="switchDropdown('days')"
                tag="div"
                name="datepicker"
                :skipIfEmpty="false" vid="datepicker"
                v-slot="{ errors, required }")
                Dropdown(:itemArray="dateArray" :open="expandDaysDD"
                  @emitDDItem="listenToggle($event, 'day')"
                  dropdownType="datepicker" :hasError="errors[0] || datePickerIncorrect")
                  template(v-slot:date-picker)
                    .date-picker-content
                      .date-picker__text(:class="{ 'filled': !isNaN(parseInt(selectedDayValue)) }") {{ selectedDayValue }}
                      .date-picker__chevron

              ValidationProvider.monthpicker-wrapper(@click="switchDropdown('month')"
                tag="div"
                name="monthpicker"
                :skipIfEmpty="false" vid="monthpicker"
                v-slot="{ errors, required }")
                Dropdown(:itemArray="monthArray" :open="expandMonthDD"
                  @emitDDItem="listenToggle($event, 'month')"
                  :hasError="errors[0] || monthPickerIncorrect"
                  dropdownType="datepicker")
                  template(v-slot:month-picker)
                    .month-picker-content
                      .month-picker__text(:class="{ 'filled': monthArray.find(month => month.title == selectedMonthValue) != undefined }") {{ selectedMonthValue }}
                      .month-picker__chevron

              ValidationProvider.yearpicker-wrapper(@click="switchDropdown('year')"
                tag="div"
                name="yearpicker"
                :skipIfEmpty="false" vid="yearpicker"
                v-slot="{ errors, required }")
                Dropdown(:itemArray="yearArray" :open="expandYearDD"
                  @emitDDItem="listenToggle($event, 'year')"
                  :hasError="errors[0] || yearPickerIncorrect"
                  dropdownType="datepicker")
                  template(v-slot:year-picker)
                    .year-picker-content
                      .year-picker__text(:class="{ 'filled': !isNaN(parseInt(selectedYearValue)) }") {{ selectedYearValue }}
                      .year-picker__chevron

            .signup-field-wrapper.region-wrapper(v-if="isSignupVisible")
              ValidationProvider.country-wrapper(@click="switchDropdown('country')"
                tag="div" name="countryPicker"
                :skipIfEmpty="false" vid="countryPicker"
                v-slot="{ errors, required }")
                Dropdown(:itemArray="getCountries" :open="expandCountryDD"
                  @emitDDItem="listenToggle($event, 'country')"
                  :hasError="errors[0] || countryPickerIncorrect" dropdownType="datepicker")
                  template(v-slot:country-picker)
                    .country-picker-content
                      .country-picker__text(:class="{ 'filled': selectedCountryValue != $t('profile.editProfile.editProfileCountryLabel') }") {{ selectedCountryValue }}
                      .country-picker__chevron

            .logemph(v-show="tabSwitcher.isPhoneActive" :class="{ 'sign': isSignupVisible, 'rem': isRemindPassword }")
              span.phone-error(v-if="phoneError") {{ $t('auth.invalidPhoneMessage') }}
              input.phone(ref="phone" id="phone" name="phone" type="text" v-model="userPhoneNumber" autocomplete="off"
                          @keypress="keypressPhone" @blur="blurPhone"
                          @input="inputPhone"
                          :placeholder="phonePlaceholder"
                          :class="{ 'error': !checkPhone }")

            .email-wrapper(v-show="tabSwitcher.isEmailActive" :class="{ 'reg': isSignupVisible, 'rem': isRemindPassword }")
              span.email-error(v-if="!checkEmail") {{ $t('auth.invalidEmailMessage') }}
              input.email(ref="email" id="email" name="email" type="text" v-model="userData.email" autocomplete="off"
                          :placeholder="$t('auth.emailPlaceholder')"
                          @blur="blurEmail"
                          :class="{ 'error': !checkEmail }")

            .rem-title-wrapper(v-show="isRemindPassword")
              .rem-title {{ !tabSwitcher.isEmailActive ? $t('auth.sentSmsLabel') : $t('auth.sentEmailLabel') }}

            .pass(v-show="isSigninVisible && !isRemindPassword" :class="{ 'reg': isSignupVisible }")
              span.pass-error(v-if="!checkPassword") {{  $t('auth.passwordLessMessage') }}
              input.password-nh(ref="password" type="password" v-model="userData.password" autocomplete="off"
                            :placeholder="$t('auth.passwordPlaceholder')"
                            @keypress="keypressPassword" :class="{ 'pswd-error': !checkPassword }")
              img.eye(v-if="isEyeOpen" :src="require('@/assets/images/auth/eye.svg')" @click="switchPasswordType()")
              img.cl-eye(v-else :src="require('@/assets/images/auth/closed_eye.svg')" @click="switchPasswordType()")

            .tos-wrapper(v-show="isSignupVisible && !isRemindPassword" :class="{ 'pswd-error': !isToSValid }")
              Checkbox(name="tosCheckbox" :title="$t('auth.confirmTermsLabel')" textColor="black"
                      :links="[$t('auth.termsAndCondLinkLabel'), $t('auth.andLabel'), $t('footer.footerPrivacyLabel')]"
                      @eventValue="listenCheckbox" :isTos="true")

            .offers-wrapper(v-show="isSignupVisible && !isRemindPassword")
              Checkbox(name="offersCheckbox" :title="$t('auth.confirmPromoNotifyLabel')"
                      @eventValue="listenCheckbox" textColor="black")

          .attention(v-if="!isRemindPassword")
            p.att-title1(v-if="!isRemindPassword") {{ $t('auth.signupAttention1Label') }}
            p.att-title2(v-if="!isRemindPassword") {{ $t('auth.signupAttention2Label') }}

          .auth-buttons
            .signin-ab-wrapper(v-if="isSigninVisible && !isRemindPassword")
              Button.signup-ab(funcToBeCalled="applyCallSigninCheck"
                    :title="$t('auth.signinButton')"
                    :isActive="isSigninABClicked"
                    @emitButton="listenBtnEvent")

            .remind-wrapper(v-if="isRemindPassword")
              Button(funcToBeCalled="applyCallSendPasswordCheck"
                    :title="$t('auth.sendPasswordButton')"
                    :isActive="isRemindClicked"
                    buttonShape="gradient"
                    @emitButton="listenBtnEvent")

            .signup-ab-wrapper(v-if="isSignupVisible")
              Button.signup-ab(funcToBeCalled="applyCallSignupCheck"
                    :title="$t('auth.signupButton')"
                    :isActive="isSignupABClicked"
                    @emitButton="listenBtnEvent")

            .forgot(v-if="isSigninVisible && !isRemindPassword"
                    @click="showForgotData") {{ $t('auth.forgotLink') }}

          .snet-wrapper(v-show="!isRemindPassword")
            .snet-wrapper__title {{ $t('auth.loginWithLabel') }}

            .social
              .social__item(v-for="(item, index) of getSocials" :key="index"
                            :class="[{'clicked': socialsClick[index], 'disabled': item.disabled}, `${item.title}`]"
                            @click="callSocial(index)")
                component.sicon(:is="getImgForSocial(index)" :isClicked="socialsClick[index]")
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import {extend} from 'vee-validate';

const CrossShadowOff = () => import('@/components/serviceComponents/svg/CrossShadowOff');
const Button = () => import('@/components/serviceComponents/ButtonSW');
const Checkbox = () => import('@/components/serviceComponents/CheckboxSW');
const TextInput = () => import('@/components/serviceComponents/textInput/TextInputSW');
const Dropdown = () => import('@/components/serviceComponents/dropdown/DropdownSW');
import Signin from './Signin.vue';

extend('minFour', value => {
  return value.length >= 4;
});

export default {
  extends: Signin,
  components: {
    Button,
    Checkbox,
    CrossShadowOff,
    Dropdown,
    TextInput,
    VueRecaptcha,
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/auth/signin_sw.scss";
</style>